import { createPropertySelectors, createSelector } from '@ngxs/store';

import { AppStateModel } from './app.state.model';
import { AppState } from './app.state';
import { AppStateHelper } from 'src/app/store/app/app.state.helper';
import { LocationSelectors } from 'src/app/store/location/location.selectors';
import _ from 'lodash';

export class AppSelectors {
  static slices = createPropertySelectors<AppStateModel>(AppState);

  static facility_details = createSelector(
    [AppSelectors.slices.facility_details],
    (facility_details) => facility_details
  );

  static getOperator = createSelector(
    [AppSelectors.slices.operator],
    (operator) => operator
  );

  static getOperatorId = createSelector(
    [AppSelectors.getOperator],
    (operator) => operator.id
  );

  static getOperatorPermissions = createSelector(
    [AppSelectors.getOperator],
    (operator) => operator.permissions
  );

  static getOperatorDiscountPermission = createSelector(
    [AppSelectors.getOperatorPermissions],
    (permissions) => permissions.discount
  );

  static getIsInputFocused = createSelector(
    [AppSelectors.slices.isInputFocused],
    (isInputFocused) => isInputFocused
  );

  static device = createSelector(
    [AppSelectors.slices.device],
    (device) => device
  );

  static payment_location = createSelector(
    [AppSelectors.slices.payment_location],
    (payment_location) => payment_location
  );

  static printers = createSelector(
    [AppSelectors.slices.printers],
    (printers) => printers
  );

  static idleSetup = createSelector(
    [AppSelectors.slices.idleSetup],
    (idleSetup) => idleSetup
  );

  static isInputFocused = createSelector(
    [AppSelectors.slices.isInputFocused],
    (isInputFocused) => isInputFocused
  );

  static tempOperator = createSelector(
    [AppSelectors.slices.tempOperator],
    (tempOperator) => tempOperator
  );

  static visiting_facilities = createSelector(
    [AppSelectors.slices.visiting_facilities],
    (visiting_facilities) => visiting_facilities
  );

  private static apiBaseUrl = createSelector(
    [AppSelectors.slices.apiBaseUrl],
    (apiBaseUrl) => apiBaseUrl
  );

  // move from app.selectors.ts
  static getApiUrl = createSelector([AppSelectors.apiBaseUrl], (apiBaseUrl) =>
    AppStateHelper.getApiUrl(apiBaseUrl)
  );

  // move from app.selectors.ts
  static getPosManagerApiUrl = createSelector(
    [AppSelectors.apiBaseUrl],
    (apiBaseUrl) => AppStateHelper.getPosManagerApiUrl(apiBaseUrl)
  );

  // move from app.selectors.ts
  // static getToken = createSelector(
  //   [AppSelectors.device],
  //   ({ token }) => token
  // );

  // move from app.selectors.ts
  static getDeviceKey = createSelector([AppSelectors.device], ({ key }) => key);

  // move from app.selectors.ts
  static getPosManagerToken = createSelector(
    [AppSelectors.getOperator],
    (operator) => operator?.manager_access_jwt
  );

  // move from app.selectors.ts
  static hasPosManagerPermission = createSelector(
    [AppSelectors.getPosManagerToken],
    (token) => !!token
  );

  // move from app.selectors.ts
  static isDeviceRegistered = createSelector(
    [AppSelectors.device],
    ({ token }) => !!token
  );

  // move from app.selectors.ts
  static getOperators = createSelector(
    [AppSelectors.facility_details],
    (facility_details) => facility_details?.operators || []
  );

  // move from app.selectors.ts
  static getCardTerminals = createSelector(
    [AppSelectors.facility_details],
    (facility_details) => facility_details?.card_terminals || []
  );

  // move from app.selectors.ts
  private static defaultPrinter = createSelector(
    [AppSelectors.slices.defaultPrinter],
    (defaultPrinter) => defaultPrinter
  );

  // move from app.selectors.ts
  static getDefaultPrinter = createSelector(
    [
      AppSelectors.defaultPrinter,
      AppSelectors.printers,
      AppSelectors.payment_location
    ],
    (defaultPrinter, printers, payment_location) =>
      AppStateHelper.getDefaultPrinter(
        defaultPrinter,
        printers,
        payment_location?.default_printer_id
      )
  );

  private static defaultCardTerminal = createSelector(
    [AppSelectors.slices.defaultCardTerminal],
    (defaultCardTerminal) => defaultCardTerminal
  );

  // move from app.selectors.ts
  static getDefaultCardTerminal = createSelector(
    [
      AppSelectors.getCardTerminals,
      AppSelectors.device,
      AppSelectors.defaultCardTerminal,
      LocationSelectors.locationType
    ],
    (card_terminals, device, defaultCardTerminal, locationType) =>
      AppStateHelper.getDefaultCardTerminal(
        card_terminals,
        device?.default_payment_terminal_id,
        defaultCardTerminal,
        locationType
      )
  );

  private static facilityDiscounts = createSelector(
    [AppSelectors.facility_details],
    (facility_details) => facility_details?.discounts || []
  );

  // move from app.selectors.ts
  static getDiscounts = createSelector(
    [AppSelectors.facilityDiscounts],
    (facilityDiscounts) => _.sortBy(facilityDiscounts, ['sort_order'])
  );

  // move from app.selectors.ts
  static isAllowTransferLocation = createSelector(
    [AppSelectors.device],
    ({ allow_pos_location_transfer }) => allow_pos_location_transfer
  );

  static enable_pos_beta_reports = createSelector(
    [AppSelectors.facility_details],
    (facility_details) => !!facility_details?.enable_pos_beta_reports
  );

  static isSuperDevice = createSelector(
    [AppSelectors.device],
    ({ super_device }) => super_device
  );
  static currentTenant = createSelector(
    [AppSelectors.device],
    ({ tenant }) => tenant
  );

  static hardwareId = createSelector(
    [AppSelectors.device],
    ({ hardware_id }) => hardware_id
  );

  static currentFacility = createSelector(
    [AppSelectors.device],
    ({ facility_id }) => facility_id
  );
}
