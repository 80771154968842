import { IEnvironment } from './environment.model';

export const environment: IEnvironment = {
  MSPOS_IS_DEPLOYED_BUILD: false,
  MSPOS_APP_VERSION: '3.19.13-dev1',
  MSPOS_API_VERSION: 3,
  MSPOS_MANAGER_API_VERSION: 1,
  MSPOS_ENVIRONMENT: 'dev',
  MSPOS_SERVER_STATUS_URL:
    'https://status.mealsuite.com/api/v2/incidents/unresolved.json'
};
