import { PosTenantType } from 'src/app/constants';
import { BaseData } from './base.model';

export enum DeviceStatus {
  Active = 'active',
  Pending_Setup = 'pending_setup',
  CREATED = 'created'
}

export enum DeviceEventType {
  MOVED = 'device_moved',
  DELETED = 'device_deleted',
  ACTIVATED = 'device_activated' // Activated or Edit device information
  // CREATED = 'device_created' // Not use
}

export interface RegisterDeviceResponse {
  status: DeviceStatus;
  device: DeviceData;
}

export interface DeviceData {
  id: number;
  facility_id: number;
  pos_location_id: number;
  name: string;
  operating_system: string;
  hardware_id: string;
  tenant: PosTenantType;
  device_type: string;
  key: string;
  allow_pos_location_transfer: boolean;
  default_payment_location_id?: number;
  default_payment_terminal_id?: number;
  default_printer_id?: number;
  active?: boolean;
  checkpoint_profile_id?: number;
  created_at?: Date;
  menuboard_setting_id?: number;
  notify_pos_location_id?: number;
  updated_at?: Date;
  super_device?: boolean;
}

export interface DeviceState extends DeviceData {
  token: string;
  status: DeviceStatus;
}

export type DevicePlatform = 'android' | 'ios' | 'web';

export interface DeviceMessage {
  event_type: DeviceEventType;
  data: DeviceData;
}

export type PosAlertType = 'webview_outdated';

export type AccountList = {
  account: BaseData[];
};
