import * as Sentry from '@sentry/angular';

import { APP_STATE_TOKEN, AppStateModel } from './app.state.model';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { AuthService, DeviceService, ModalService } from 'src/app/services';
import { CommonConstant, PageConstant } from 'src/app/constants';
import {
  CompleteDeviceRegistration,
  FocusInput,
  GetAuthentication,
  GetCashoutReport,
  GetDeviceRegistrationCode,
  GetPrinters,
  InputLosesFocus,
  LoadHardwareDetails,
  LoadHardwareDetailsSuccess,
  Login,
  LoginSelfService,
  Logout,
  OpenCashDrawer,
  ResetApplication,
  SelectDefaultPrinter,
  SelectPaymentLocation,
  SelectPaymentTerminal,
  SetApiUrl,
  SetDefaultDietAndTexture,
  SetVisitingFacilities,
  StartIdleTime,
  StartLRAndSentrySession,
  StopIdle,
  SwitchDeviceLocation,
  SwitchDeviceLocationError,
  SwitchDeviceLocationSuccess,
  TempLogin,
  TempLogout,
  PatchAuthOperator,
  UpdatePinCode,
  UpdatedDevice,
  WSCreatePOSOperator,
  WSDeletePOSOperator,
  WSUpdatePOSOperator,
  ChangePosLocation
} from './app.action';
import { ExternalScreenProvider, IdleProvider } from 'src/app/providers';
import {
  DeviceData,
  DevicePlatform,
  DeviceStatus,
  IdleParams,
  LocationType
} from 'src/app/models';
import { EMPTY, concat, forkJoin, from, lastValueFrom } from 'rxjs';
import {
  GetLocation,
  GetLocations,
  SetCurrentMealId,
  UpdateSelectedMealId
} from 'src/app/store/location/location.action';
import {
  LOCATION_STATE_TOKEN,
  LocationState
} from 'src/app/store/location/location.state';
import { MenuController, NavController } from '@ionic/angular';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import {
  insertItem,
  patch,
  removeItem,
  updateItem
} from '@ngxs/store/operators';

import Bowser from 'bowser';
import { Capacitor } from '@capacitor/core';
import { GlobalService } from 'src/app/services/global/global.service';
import { HARDWARE_DEVICE_STATE_TOKEN } from 'src/app/store/hardware-device/hardware-device.model';
import { HardwareDeviceState } from 'src/app/store/hardware-device/hardware-device.state';
import { Injectable } from '@angular/core';
import { LogRocketProvider } from 'src/app/providers/logrocket.provider';
import { MealHelper } from 'src/app/helpers';
import { MenuState } from 'src/app/store/menu/menu.state';
import { PosProvider } from 'src/app/providers/pos.provider';
import { Router } from '@angular/router';
import { SelectTicket } from 'src/app/store/tickets/tickets.action';
import { StartOver } from 'src/app/store/self-service/self-service.action';
import { StateResetAll } from 'ngxs-reset-plugin';
import { environment } from 'src/environments/environment';
import { format } from 'date-fns';
import { DeviceHelper } from 'src/app/helpers/device.helper';
import { NotifyOldWebViewDevice } from 'src/app/store/hardware-device/hardware-device.action';
import { AppUtil } from 'src/app/utils/app.util';
import { AppStateHelper } from 'src/app/store/app/app.state.helper';

@State({
  name: APP_STATE_TOKEN,
  defaults: {
    apiBaseUrl: '',
    device: {
      id: null,
      status: null,
      name: '',
      facility_id: null,
      pos_location_id: null,
      operating_system: '',
      hardware_id: null,
      device_type: '',
      tenant: 'synergy',
      key: null,
      allow_pos_location_transfer: false,
      token: null
    },
    defaults: {
      diet_id: null,
      texture_id: null
    },
    facility_details: null,
    operator: null,
    tempOperator: null,
    payment_location: null,
    visiting_facilities: [],
    idleSetup: false,
    printers: [],
    defaultPrinter: null,
    defaultCardTerminal: null,
    isInputFocused: false
  }
})
@Injectable()
export class AppState {
  constructor(
    private readonly store: Store,
    private readonly deviceService: DeviceService,
    private readonly authService: AuthService,
    private readonly globalService: GlobalService,
    private readonly modalService: ModalService,
    private readonly menuController: MenuController,
    private readonly posProvider: PosProvider,
    private readonly idleProvider: IdleProvider,
    private readonly logRocketProvider: LogRocketProvider,
    private readonly externalScreenProvider: ExternalScreenProvider,
    private readonly router: Router,
    private readonly navController: NavController
  ) {}

  //#region Actions
  //#region App Setup

  //Sets host and domain data
  @Action(SetApiUrl)
  setApiHost({ patchState }: StateContext<AppStateModel>, { url }: SetApiUrl) {
    patchState({
      apiBaseUrl: url
    });
  }

  //Sets default diet and texture by the tenant
  @Action(SetDefaultDietAndTexture)
  setDefaultDietAndTexture(
    { patchState }: StateContext<AppStateModel>,
    { tenant }: SetDefaultDietAndTexture
  ) {
    let diet_id = CommonConstant.REGULAR_DIET,
      texture_id = CommonConstant.REGULAR_TEXTURE;
    if (tenant) {
      const defaultValues = CommonConstant.TENANT_DIET_TEXTURE[tenant];
      if (defaultValues) {
        diet_id = defaultValues.diet_id;
        texture_id = defaultValues.texture_id;
      }
    }

    patchState({
      defaults: {
        diet_id,
        texture_id
      }
    });
  }

  //#endregion

  //#region Device Registration

  //Get code to register device
  @Action(GetDeviceRegistrationCode)
  getCode(ctx: StateContext<AppStateModel>) {
    const { apiBaseUrl, device: oldDevice } = ctx.getState();
    const { uuid, local_os } = this.store.selectSnapshot(
      HARDWARE_DEVICE_STATE_TOKEN
    );

    return this.deviceService.getDevice(apiBaseUrl, uuid, local_os?.name).pipe(
      tap(({ status, device }) => {
        ctx.patchState({
          apiBaseUrl: AppUtil.configHost(device.tenant),
          device: {
            ...oldDevice,
            ...device,
            status,
            token: device.key + ':' + uuid
          }
        });
      }),
      filter(({ status }) => status === 'active'),
      switchMap(({ device }) =>
        ctx.dispatch([
          new CompleteDeviceRegistration(),
          new SetDefaultDietAndTexture(device.tenant)
        ])
      )
    );
  }

  //Set token
  @Action(CompleteDeviceRegistration)
  onDeviceRegistrationComplete(ctx: StateContext<AppStateModel>) {
    //Initialize Datadog on production environments
    // if (environment.MSPOS_IS_DEPLOYED_BUILD) {
    //   const state = ctx.getState();
    //   datadogLogs.init({
    //     clientToken: 'pub9608f9efd4747df36390d0878dc8ec2e',
    //     site: 'datadoghq.com',
    //     forwardErrorsToLogs: true,
    //     sessionSampleRate: 100,
    //     env: environment.MSPOS_ENVIRONMENT,
    //     service: 'POS',
    //     version: environment.MSPOS_APP_VERSION
    //   });

    //   datadogLogs.addLoggerGlobalContext('user_id', state.device.uuid);
    // }

    const actions = [new GetLocations(), new GetAuthentication()];
    const { platform, deviceInfo } = this.store.selectSnapshot(
      HARDWARE_DEVICE_STATE_TOKEN
    );

    if (
      // Check if the device is an android device and the webview version is less than the minimum required
      platform === 'android' &&
      DeviceHelper.isOldWebViewVersion(deviceInfo.webViewVersion)
    ) {
      actions.push(new NotifyOldWebViewDevice());
    }

    return ctx.dispatch(actions);
  }

  //Device was updated in Admin Site
  @Action(UpdatedDevice)
  updatedDevice(ctx: StateContext<AppStateModel>, { device }: UpdatedDevice) {
    const state = ctx.getState();
    const oldDevice = ctx.getState().device;
    const isDeviceActive = state.device.status === DeviceStatus.Active;
    const isPosLocationChanged =
      device.pos_location_id !== oldDevice.pos_location_id;

    console.log('debug: ', this.router.url);
    // If active route url is /device-registration, activate the device
    // Otherwise, reset the application
    if (window.location.href.includes(PageConstant.DEVICE_REGISTRATION_PAGE)) {
      ctx.patchState({
        device: {
          ...state.device,
          ...device
        },
        operator: null
      });
      ctx.dispatch([
        new StateResetAll(HardwareDeviceState, AppState, MenuState),
        new CompleteDeviceRegistration(),
        new SetDefaultDietAndTexture(device.tenant)
      ]);
    } else if (!isDeviceActive || isPosLocationChanged) {
      ctx.dispatch(new ResetApplication());
    } else {
      ctx.patchState({
        device: {
          ...state.device,
          ...device,
          default_payment_location_id: device.default_payment_location_id
        },
        payment_location: this.store
          .selectSnapshot(LOCATION_STATE_TOKEN)
          .location.payment_locations.find(
            (i) => i.id === device.default_payment_location_id
          )
      });
    }
  }
  //#endregion

  //#region Auth

  //Get authentication data
  @Action(GetAuthentication)
  getAuthentication(ctx: StateContext<AppStateModel>) {
    return from(this.authService.getAuthInfo()).pipe(
      tap(({ facility_details }) =>
        ctx.patchState({
          facility_details
        })
      ),
      switchMap(() =>
        ctx.dispatch([
          new GetPrinters(),
          new GetLocation(ctx.getState().device.pos_location_id)
        ])
      )
    );
  }

  //Update pin code of logged operator
  @Action(UpdatePinCode)
  updatePinCode(
    { dispatch }: StateContext<AppStateModel>,
    { currentOperator, pin_code, processLogin }
  ) {
    return this.authService.updatePinCode(currentOperator.id, pin_code).pipe(
      tap((operator) => {
        if (operator) {
          const newOperator = Object.assign({}, currentOperator, { pin_code });
          if (processLogin) {
            dispatch(new Login(newOperator));
          }
        }
      })
    );
  }

  @Action(Login)
  login(
    { patchState, dispatch }: StateContext<AppStateModel>,
    { operator }: Login
  ) {
    patchState({ operator });
    const posLocation =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
    const currentMealId =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).currentMealId;
    const locationType = posLocation?.type;
    const mealId = MealHelper.getMealIdByTime(posLocation?.meal_end_times);
    const isServicesLocation = locationType === LocationType.Services;
    const idleSetup: boolean =
      this.store.selectSnapshot(APP_STATE_TOKEN).idleSetup;

    if (currentMealId !== mealId) {
      dispatch(new SetCurrentMealId(mealId));
    }

    const preDispatchedActions: any[] = [];
    const actions = [
      new UpdateSelectedMealId(
        isServicesLocation ? undefined : mealId,
        this.formatDate(new Date())
      ),
      new SetVisitingFacilities()
    ];

    if (environment.MSPOS_IS_DEPLOYED_BUILD) {
      if (!idleSetup) {
        preDispatchedActions.push(new StartIdleTime());
      }

      preDispatchedActions.push(new StartLRAndSentrySession(operator));
    }

    const path =
      locationType === LocationType.DiningRoom
        ? PageConstant.DINING_ROOM_PAGE
        : locationType === LocationType.Services
        ? PageConstant.SERVICES_BOOKING_PAGE
        : PageConstant.QUICK_SERVICE_PAGE;

    return concat([
      forkJoin([
        dispatch(preDispatchedActions),
        from(this.navController.navigateRoot(path))
      ]),
      dispatch(actions)
    ]);
  }

  @Action(PatchAuthOperator)
  patchAuthOperator(
    { setState }: StateContext<AppStateModel>,
    { operator }: PatchAuthOperator
  ) {
    setState((prev) => {
      const v =
        typeof operator === 'function' ? operator(prev.operator) : operator;

      if (prev.operator) {
        return {
          ...prev,
          operator: {
            ...prev.operator,
            ...v
          }
        };
      }

      if (prev.tempOperator) {
        return {
          ...prev,
          tempOperator: {
            ...prev.tempOperator,
            ...v
          }
        };
      }

      return prev;
    });
  }

  @Action(StartLRAndSentrySession)
  startLogRocket(
    { getState }: StateContext<AppStateModel>,
    { operator }: StartLRAndSentrySession
  ) {
    //Start LogRocket if app not running on local
    const state = getState();
    const location = this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
    const facility_details = state.facility_details;
    const device = state.device;
    const operatorId = operator.operator_id;
    const logRocketSessionName = `${operator.display_name} - ${operatorId} | ${device.name} - ${device.key}`;
    const logRocketSessionEmail = `${location.name} @ ${facility_details.name}`;

    //Start session recording if enabled in the facility
    if (facility_details.enable_session_recording) {
      this.logRocketProvider.startNewSession();
      this.logRocketProvider.identifyUser(
        `${logRocketSessionName} | ${logRocketSessionEmail}`,
        {
          name: logRocketSessionName,
          email: logRocketSessionEmail
        }
      );
    }

    // Identify User in Sentry
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: operatorId,
        username: logRocketSessionName,
        email: logRocketSessionEmail
      });
    });
  }

  @Action(Logout)
  async logout(ctx: StateContext<AppStateModel>) {
    this.posProvider.resetPosChannelSubscription();
    if (this.externalScreenProvider.isActive) {
      this.externalScreenProvider.displayHome();
    }
    this.menuController.close();
    const actions = [
      new SelectTicket(),
      new StopIdle(),
      new GetAuthentication()
    ];

    // this select ticket with trigger unlock ticket must be finished before cleaning the state of app
    await lastValueFrom(this.store.dispatch(actions));

    this.store
      .dispatch(new StateResetAll(HardwareDeviceState, AppState, LocationState))
      .subscribe(() => {
        ctx.patchState({
          operator: null
        });
        this.navController.navigateRoot(PageConstant.LOGIN_PAGE, {
          animationDirection: 'back'
        });
      });
  }

  @Action(TempLogout)
  tempLogout(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ tempOperator: null });
  }

  @Action(LoginSelfService)
  loginSelfService(
    ctx: StateContext<AppStateModel>,
    { operator_id }: LoginSelfService
  ) {
    const state = ctx.getState();
    const operator = state.facility_details.operators.find(
      (i) => i.id === operator_id
    );
    ctx.patchState({ operator });

    if (environment.MSPOS_IS_DEPLOYED_BUILD) {
      ctx.dispatch(new StartLRAndSentrySession(operator));
    }
  }

  @Action(TempLogin)
  tempLogin(ctx: StateContext<AppStateModel>, { operator }: TempLogin) {
    ctx.patchState({ tempOperator: operator });
  }

  //#endregion

  //#region Tool
  @Action(GetPrinters)
  getPrinters({ patchState }: StateContext<AppStateModel>) {
    return this.globalService
      .getPrinters()
      .pipe(tap((printers) => patchState({ printers })));
  }

  @Action(SelectDefaultPrinter)
  selectDefaultPrinter(
    ctx: StateContext<AppStateModel>,
    { printer_id }: SelectDefaultPrinter
  ) {
    const printers = ctx.getState().printers;
    const defaultPrinter = printers.find((p) => p.id === printer_id).id;
    const deviceId = ctx.getState().device.id;
    return this.deviceService
      .updateDevice(deviceId, { default_printer_id: defaultPrinter })
      .pipe(tap(() => ctx.patchState({ defaultPrinter })));
  }

  @Action(ChangePosLocation)
  changePosLocation(
    ctx: StateContext<AppStateModel>,
    { deviceData }: ChangePosLocation
  ) {
    const deviceId = ctx.getState().device.id;
    return this.deviceService.updateDevice(deviceId, deviceData).pipe(
      tap(() =>
        ctx.patchState({
          device: { ...ctx.getState().device, ...deviceData }
        })
      )
    );
  }

  //Get the Cashout Report
  @Action(GetCashoutReport)
  getCashoutReport(
    ctx: StateContext<AppStateModel>,
    { printer_id }: GetCashoutReport
  ) {
    const state = ctx.getState();

    return this.globalService.getCashoutReport(
      printer_id,
      state.payment_location.id,
      state.operator.id
    );
  }

  //Open Cash Drawer
  @Action(OpenCashDrawer)
  openCashDrawer(
    { getState }: StateContext<AppStateModel>,
    { operatorId, fromTools }: OpenCashDrawer
  ) {
    const {
        operator,
        payment_location,
        defaultPrinter: default_printer,
        printers
      } = getState(),
      operator_id = operatorId || operator.id;

    const paymentLocationId = AppStateHelper.paymentLocationId(
      payment_location,
      default_printer,
      printers,
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location
        .payment_locations,
      fromTools
    );

    return this.globalService.openCashDrawer(paymentLocationId, operator_id);
  }

  //Update Selected Payment Location
  @Action(SelectPaymentLocation)
  selectPaymentLocation(
    { patchState }: StateContext<AppStateModel>,
    payload: SelectPaymentLocation
  ) {
    const paymentLocations =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location
        .payment_locations;
    const payment_location = paymentLocations.find(
      (i) => i.id === payload.paymentLocationId
    );

    patchState({
      payment_location
    });
  }

  //Update Selected Card Terminal
  @Action(SelectPaymentTerminal)
  selectPaymentTerminal(
    ctx: StateContext<AppStateModel>,
    { id }: SelectPaymentTerminal
  ) {
    const state = ctx.getState();
    const cardTerminals = state.facility_details.card_terminals;
    const selectedCardTerminal = cardTerminals.find((p) => p.id === id);
    const deviceId = state.device.id;

    return this.deviceService
      .updateDevice(deviceId, { default_payment_terminal_id: id })
      .pipe(
        tap(() => ctx.patchState({ defaultCardTerminal: selectedCardTerminal }))
      );
  }

  @Action(SetVisitingFacilities)
  setVisitingFacilities({ patchState }: StateContext<AppStateModel>) {
    return this.globalService
      .getVisitingFacilities()
      .pipe(tap((visiting_facilities) => patchState({ visiting_facilities })));
  }

  @Action(SwitchDeviceLocation)
  switchDeviceLocation(
    ctx: StateContext<AppStateModel>,
    { pos_location_id }: SwitchDeviceLocation
  ) {
    const state = ctx.getState();
    return this.deviceService
      .transferDeviceLocation(state.device.id, pos_location_id)
      .pipe(
        tap(() => ctx.dispatch(new SwitchDeviceLocationSuccess())),
        //No need to do anything else here. WS Event will handle the rest
        catchError(() =>
          ctx.dispatch(
            new SwitchDeviceLocationError(
              `There was an error while switching your device POS Location`
            )
          )
        )
      );
  }

  //#endregion

  //#region Idle

  @Action(StartIdleTime)
  startIdleTime(ctx: StateContext<AppStateModel>) {
    // setup idle
    const currentLocation =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
    const timeout =
      currentLocation && currentLocation.app_timeout
        ? currentLocation.app_timeout
        : 300;
    if (timeout) {
      // setup idle
      // POS-772 incase the app_timeout is too small (<= 60 ~ POS_TIMEOUT), it should get a half of app_timeout for idle and half for countdown/timeout
      const defaultTimeOut =
        timeout <= CommonConstant.TIMEOUT_SETUP.POS_TIMEOUT
          ? timeout / 2
          : CommonConstant.TIMEOUT_SETUP.POS_TIMEOUT;
      const params: IdleParams = {
        name: 'pos-app',
        idle: timeout
          ? timeout - defaultTimeOut
          : CommonConstant.TIMEOUT_SETUP.POS_IDLE,
        timeout: defaultTimeOut,
        onTimeout: () => {
          this.modalService.closeAllModals();
          // Self Service Location will start over, any other location will logout
          if (currentLocation.type === LocationType.SelfServe) {
            return this.store.dispatch(new StartOver(true, true));
          } else {
            this.store.dispatch(new Logout());
          }
        }
      };
      this.idleProvider.setupIdle(params);
      ctx.patchState({ idleSetup: true });
    }
  }

  @Action(StopIdle)
  stopIdle(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    if (state.idleSetup) {
      ctx.patchState({ idleSetup: false });
      this.idleProvider.stopIdle();
    }
  }

  //#endregion

  @Action(FocusInput)
  focusInput(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ isInputFocused: true });
  }

  @Action(InputLosesFocus)
  InputLosesFocus(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ isInputFocused: false });
  }

  @Action(ResetApplication)
  async resetApplication(ctx: StateContext<AppStateModel>) {
    //Close side menu if open
    await this.menuController.close();

    //Unsubscribe from POS Channel
    this.posProvider.resetPosChannelSubscription();

    //Reset External Display to Home view if it is active
    if (this.externalScreenProvider.isActive) {
      this.externalScreenProvider.displayHome();
    }

    //Stop Idle Tracking
    this.idleProvider.stopIdle();

    //Reset all states
    ctx.dispatch(new StateResetAll(HardwareDeviceState));

    //Navigate to Device Registration Page
    this.router.navigateByUrl(PageConstant.DEVICE_REGISTRATION_PAGE, {
      replaceUrl: true
    });
  }

  private formatDate(date: number | Date, formatString: string = 'yyyy-MM-dd') {
    return format(date, formatString);
  }
  //#endregion

  @Action(WSCreatePOSOperator)
  wsCreatePOSOperator(
    { setState }: StateContext<AppStateModel>,
    { operator }: WSCreatePOSOperator
  ) {
    setState(
      patch({
        facility_details: patch({
          operators: insertItem(operator)
        })
      })
    );
  }

  @Action(WSUpdatePOSOperator)
  wsUpdatePOSOperator(
    { setState }: StateContext<AppStateModel>,
    { operator }: WSUpdatePOSOperator
  ) {
    setState(
      patch({
        facility_details: patch({
          operators: updateItem((item) => item.id === operator.id, operator)
        })
      })
    );
  }

  @Action(WSDeletePOSOperator)
  wsDeletePOSOperator(
    { setState }: StateContext<AppStateModel>,
    { pos_operator_id }: WSDeletePOSOperator
  ) {
    setState(
      patch({
        facility_details: patch({
          operators: removeItem((item) => item.id === pos_operator_id)
        })
      })
    );
  }

  @Action(LoadHardwareDetails)
  loadHardwareDetails(
    ctx: StateContext<AppStateModel>,
    { deviceId, deviceInfo, nfcEnabled }: LoadHardwareDetails
  ) {
    const { os: local_os } = Bowser.parse(window.navigator.userAgent);
    const isNativePlatform = Capacitor.isNativePlatform();
    const platform = Capacitor.getPlatform() as DevicePlatform;
    const uuid: string =
      isNativePlatform && deviceId
        ? deviceId.identifier ||
          deviceId['uuid'] ||
          this.deviceService.getUuid().uuid
        : this.deviceService.getUuid().uuid;

    ctx.dispatch(
      new LoadHardwareDetailsSuccess({
        deviceInfo,
        local_os,
        uuid,
        platform,
        nfcEnabled,
        version: environment.MSPOS_APP_VERSION
      })
    );
  }

  @Action(Logout)
  closePaidTicketsAfterLogout(ctx: StateContext<AppStateModel>) {
    return this.globalService
      .closeFullyPaidTickets(ctx.getState().device.pos_location_id)
      .pipe(catchError(() => EMPTY));
  }
}
